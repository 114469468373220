import {Link} from "gatsby";
import * as React from "react";
import {ASSETS} from "../common/images";

const MobileAppDevelopmentList = () => {
    const iosProjects = [
        {
            projectImg: ASSETS.PORTFOLIO.QualityMindApp,
            frameworkName: '',
            projectName: 'Quality Minds',
            description: 'Quality Mind is a proven program helping people to improve their lives by reducing mental pressure. A personal Mind Mentor provides..',
            url: 'quality-minds',
        },
        {
            projectImg: ASSETS.PORTFOLIO.RxTro,
            frameworkName: '',
            projectName: 'RxTro',
            description: 'RxTro is a centralized industry platform for medical representatives in Australia. It helps organize daily visit..',
            url: 'rx-tro',
        },
        {
            projectImg: ASSETS.PORTFOLIO.FreshyApp,
            frameworkName: '',
            projectName: 'Freshy Helpline',
            description: 'Moving to new places can be stressful! You need accommodation, vehicles, jobs, and other basic things. The Freshy Helpline app helps..',
            url: 'freshy-helpline-mobile',
        },
        {
            projectImg: ASSETS.PORTFOLIO.TadpoleApp,
            frameworkName: '',
            projectName: 'Tadpole Rides',
            description: 'Tadpole applications serve and fulfill the riding requirements of the users. It further allows them to earn extra benefits through referrals.',
            url: 'tadpole-rides-mobile',
        },
        {
            projectImg: ASSETS.PORTFOLIO.MeetingManagmentApp,
            frameworkName: '',
            projectName: '360 Meeting Management',
            description: 'Meetings are opportunities for discussions and collaborations. The 360 Meeting Management app helps in building great meeting habits. The..',
            url: 'meeting-managment',
        },
        {
            projectImg: ASSETS.PORTFOLIO.VegatoApp,
            frameworkName: '',
            projectName: 'Vegato',
            description: 'Vegato App offers the luxury of discovering fresh and quality produce that is delivered at the doorstep. In addition, the platform allows..',
            url: 'vageto',
        },
        {
            projectImg: ASSETS.PORTFOLIO.SideffectApp,
            frameworkName: '',
            projectName: 'Sideffect Alert',
            description: 'Sideeffect Alert app is based on the concept of harm minimization. The alert and awareness system offers the subscribers real-time data on.. ',
            url: 'sideffect-alert',
        },
    ]
    return (
        <div className="container">
                <div className="row technology-tab-row">
                    {iosProjects.map((row) => (
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Link to={`/portfolio/project/${row.url}`} className="technology-common-tab-col figure-wrap">
                                <div className="technology-common-tab-header tab-img-effect">
                                    <div className="img-wrap">
                                        <img src={row.projectImg} alt="Mobile-app-project|GeekyBones"/>
                                    </div>
                                    <div className="text">
                                        <h3>{row. projectName}</h3>
                                    </div>
                                    <div className="line top"></div>
                                    <div className="line bottom"></div>
                                    <div className="line left"></div>
                                    <div className="line right"></div>
                                </div>
                                <div className="technology-common-tab-body">
                                    <div className="tab-body-heading">
                                        <h5>{row.projectName}</h5>
                                        <p>{row.frameworkName}</p>
                                    </div>
                                    <p>{row.description}</p>
                                    <div className="technology-common-tab-footer">
                                        <Link to={`/portfolio/project/${row.url}`}>View More</Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
        </div>
    )
}
export default MobileAppDevelopmentList;
